<script>
  import { cubicInOut } from "svelte/easing";
  import { fade } from "svelte/transition";
  import OpenMenu from "./icons/bars-solid.svelte";
  import CloseMenu from "./icons/xmark-solid.svelte";

  let isOpen = $state(false);

  function toggleNav() {
    isOpen = !isOpen;
  }
</script>

<button onclick={toggleNav} class="z-50 ml-auto lg:hidden">
  {#if !isOpen}
    <OpenMenu />
  {:else}
    <CloseMenu />
  {/if}
</button>

<nav>
  {#if isOpen}
    <div
      transition:fade={{ delay: 0, duration: 350, easing: cubicInOut }}
      class="fixed inset-0 z-40 flex flex-col items-center justify-center space-y-10 bg-gradient-to-r from-gray-500 to-gray-800"
    >
      <ul class="space-y-6 text-center">
        <li>
          <a
            onclick={toggleNav}
            class="font-subtitle text-3xl font-medium tracking-wider text-gray-50"
            href="#about"
          >
            About
          </a>
        </li>
        <li>
          <a
            onclick={toggleNav}
            class="font-subtitle text-3xl font-medium tracking-wider text-gray-50"
            href="#services"
          >
            Services
          </a>
        </li>
        <li>
          <a
            onclick={toggleNav}
            class="font-subtitle text-3xl font-medium tracking-wider text-gray-50"
            href="#contact"
          >
            Contact
          </a>
        </li>
      </ul>
    </div>
  {/if}

  <div class="flex items-center space-x-10">
    <ul class="hidden lg:flex lg:space-x-10">
      <li>
        <a
          class="font-subtitle font-medium tracking-wider text-gray-50 underline-offset-[6px] hover:underline"
          href="#about"
        >
          About
        </a>
      </li>
      <li>
        <a
          class="font-subtitle font-medium tracking-wider text-gray-50 underline-offset-[6px] hover:underline"
          href="#services"
        >
          Services
        </a>
      </li>
      <li>
        <a
          class="font-subtitle font-medium tracking-wider text-gray-50 underline-offset-[6px] hover:underline"
          href="#contact"
        >
          Contact
        </a>
      </li>
    </ul>
    <div class="hidden lg:block">
      <a
        class="font-subtitle inline-block rounded-lg bg-gray-50 px-6 py-2 text-sm font-medium tracking-wider text-gray-900 transition duration-300 ease-in-out hover:scale-110 lg:py-3 lg:tracking-widest"
        href="tel:508-366-1201"
      >
        Give us a call
      </a>
    </div>
  </div>
</nav>
